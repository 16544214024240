import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import CustomSoftware from "../../assets/images/services/custom.avif"
import web from "../../assets/images/services/web.jpeg"
import mobile from "../../assets/images/services/mobile.png"
import uiux from "../../assets/images/services/uiux.gif"
import quality from "../../assets/images/services/quality.png"
import maintanence from "../../assets/images/services/maintanence.svg"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT"
                title="Software Development Consulting Services India, USD$ 100K Start Projects"
                description="Project Start From USD$ 100K - Programming Languages, Frameworks and Libraries, Databases, DevOps Cloud Platforms, Frontend Development, Backend Development."
                keywords="JavaScript, Python, Java, C#, Ruby, Angular, React, Vue.js, Express.js, Django, Spring Boot, Ruby on Rails, SQL databases (e.g., MySQL, PostgreSQL, SQL Server), NoSQL databases (e.g., MongoDB, Couchbase, Cassandra), Redis, Elasticsearch, Git, Docker, Kubernetes, Jenkins, Ansible, Puppet, Chef, Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), Heroku, DigitalOcean, HTML, CSS, Sass/Less, Webpack, Babel, Storybook, Express.js, Flask, Django, Spring Boot, RESTful and GraphQL APIs, Socket.IO, Jest, Mocha, Jasmine, Selenium, Cypress, JUnit, TestNG, Mockito, Pytest" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />

                <div className="service-container">
                    <div className="left-sec">
                        <h1>Custom Software Development</h1>
                        <p>
                            At OurCompany, we specialize in crafting custom software solutions tailored to your specific business needs. With our team of experienced developers, we strive to deliver innovative and efficient solutions that drive your business forward. Our approach involves close collaboration with clients throughout the development process, ensuring that the final product meets their expectations and surpasses industry standards.
                        </p>
                    </div>
                    <div className="right-sec">
                        <img src={CustomSoftware} alt="Custom Software Development" className="image" />
                    </div>
                </div>

                <div className="service-container">
                    <div className="left-sec">
                        <img src={web} alt="Custom Software Development" className="image" />

                    </div>
                    <div className="right-sec">
                        <h1>Web Application Development</h1>
                        <p>
                            Web applications are an essential part of modern business operations, enabling companies to deliver services and interact with customers online. At OurCompany, we specialize in developing scalable, secure, and user-friendly web applications tailored to your specific business needs. Whether you need a simple website, a complex web portal, or an e-commerce platform, our team of experienced developers will work closely with you to deliver a solution that exceeds your expectations.
                        </p>

                    </div>
                </div>

                <div className="service-container">
                    <div className="left-sec">
                        <h1>Mobile App Development</h1>
                        <p>
                            Mobile apps have become a crucial channel for businesses to engage with their customers and enhance their brand presence. At OurCompany, we specialize in creating innovative and feature-rich mobile apps for iOS and Android platforms. Whether you need a native app, a cross-platform app, or a progressive web app, our team of experienced developers will work closely with you to bring your ideas to life and deliver a seamless mobile experience for your users.
                        </p>
                    </div>
                    <div className="right-sec">
                        <img src={mobile} alt="Custom Software Development" className="image" />
                    </div>
                </div>

                <div className="service-container">
                    <div className="left-sec">
                        <img src={uiux} alt="Custom Software Development" className="image" />

                    </div>
                    <div className="right-sec">
                        <h1>UI/UX Design</h1>
                        <p>
                            User interface (UI) and user experience (UX) design play a crucial role in ensuring the success of digital products and applications. At OurCompany, we understand the importance of creating intuitive, visually appealing, and user-friendly interfaces that enhance the overall user experience.
                        </p>

                    </div>
                </div>

                <div className="service-container">
                    <div className="left-sec">
                        <h1>Quality Assurance & Testing</h1>
                        <p>
                            Quality assurance (QA) and testing are essential components of the software development lifecycle, ensuring that your applications meet high standards of quality, performance, and reliability. At OurCompany, we have a dedicated team of QA engineers who specialize in testing software products across various platforms and devices to identify and address any issues or defects before they impact your users.
                        </p>
                    </div>
                    <div className="right-sec">
                        <img src={quality} alt="Custom Software Development" className="image" />
                    </div>
                </div>

                <div className="service-container">
                    <div className="left-sec">
                        <img src={maintanence} alt="Custom Software Development" className="image" />

                    </div>
                    <div className="right-sec">
                        <h1>Software Maintenance & Support</h1>
                        <p>
                            Continuous maintenance and support are vital for ensuring the long-term success and sustainability of your software applications. At OurCompany, we offer comprehensive maintenance and support services to help you keep your applications up-to-date, secure, and running smoothly, allowing you to focus on your core business activities.
                        </p>

                    </div>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query SoftwareDevelopmentPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
